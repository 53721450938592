div.nav-item {
    margin: 7px;
}

div.nav-item a {
    text-decoration: none;
    color: black;
}

div.nav-item a.active {
    font-weight: bold;
}

div.nav-item .span--disabled {
    color: lightgray;
}