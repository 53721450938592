div.custom-row {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

div.custom-row > :not(:last-child){
    margin: 0 10px 0 0;
}

div.custom-row > form {
    height: inherit;
    flex-grow: 6;
}

div.custom-row > button {
    width: 100px;
}
