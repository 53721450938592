.key-value {
    margin: 10px 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.key-value .key-value-form {
    flex-grow: 1
}

.key-value > :not(:last-child) {
    margin-right: 10px;
}

.button-group {
    display: flex;
    flex-wrap: nowrap;
}

.button-group > :not(:last-child) {
    margin-right: 10px;
}

.button-group .button-group__button {
    width: 100px;
    height: 38px;
}
