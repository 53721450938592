.custom-field {
    width: 100%;
    height: 100%;
}

.custom-field__input {
    width: 100%;
    height: 100%;
    padding: 0 5px;
}

.custom-field__input--error {
    border: 2px solid red;
}

.custom-field__label {
    padding-right: 5px;
}