.key-value-form {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.key-value-form > :not(:last-child) {
    margin: 0 10px 0 0;
}

.key-value-form .custom-field {
    flex-grow: 1;
    height: inherit;
}